.confirm-modal {
  padding: 36px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.015em;
    color: $color_trout;
  }

  &__button-group {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 44px;

    .button {
      width: 50%;
    }
  }
}

.nft-profile {
  &__tab {
    .ant-tabs-nav {
      &::before {
        border-bottom: unset;
        display: none;
      }
    }

    .ant-tabs-tab {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin: 0px;
      padding: 0 0 12px 0;
      border-top: 0px;
      background-color: $color_white;

      .ant-tabs-tab-btn {
        padding-right: 10px !important;
      }

      &:nth-child(2) {
        &::before {
          content: '';
          height: 13px;
          background-color: $color_scarlet;
          width: 2px;
          margin-right: 8px;
        }
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab-btn {
        padding: 0px 5px;
      }

      .ant-tabs-tab-btn:last-child {
        border-right: unset;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  &-preview {
    .preview-nft {
      &__content {
        height: 383px;
      }
    }

    &__sale-order {
      padding-top: 12px;

      .sale-order {
        &-quantity {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: $color_scarlet;
          gap: 7px;

          &__symbol {
            opacity: 0.5;
          }
        }

        &-price {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: $color_gray;
        }
      }
    }
  }

  &-content {
    background: $color_big_stone;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    height: 383px;

    &__audio {
      height: 100%;
      position: relative;

      .audio {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .controller {
          width: 100%;
          position: unset;
          margin: 0px 10px;
        }
      }

      .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .ant-image-img {
      height: 350px;
      object-fit: contain;
      width: 100%;
    }

    video {
      height: 350px;
    }

    .model-viewer {
      height: 350px;
      margin: auto;

      model-viewer {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__type {
    display: flex;
    justify-content: center;
    padding-top: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: $color_tundora;
  }
}

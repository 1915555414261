.item-with-label {
  display: flex;
  align-items: center;

  .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    margin-right: 10px;
  }

  .content {
    font-size: 16px;
    line-height: 16px;
  }
}

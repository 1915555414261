.revenue-management-search-form {
  &__row {
    display: flex;
    align-items: center;
    margin-right: unset !important;
    padding: 0px;
  }

  .col-date {
    max-width: 149px;
    min-width: 149px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    &__date {
      height: 40px;
    }
  }

  .col-select {
    min-width: 108px;
  }

  .setting-icon {
    display: flex;
    justify-content: flex-end;
    padding-right: 0px !important;

    &__menu {
      padding: 16px 24px;
      position: relative;
      width: 286px;
      background: $color_white;
      box-shadow: 0px 16px 64px rgba(31, 47, 70, 0.2);
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;

      .ant-checkbox-wrapper {
        width: 46%;
        padding-top: 6px;
        padding-bottom: 6px;
        color: $color_trout;
      }

      .row-label {
        margin-bottom: 22px;
      }

      .label {
        font-weight: 600;
        font-size: 14px;
        color: $color_cinder;
      }

      .total-selected {
        font-size: 12px;
        color: $color_trout;
      }

      .app-button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        margin-top: 26px;
        background-color: $color_scarlet;
        color: $color_white;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.015em;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $color_scarlet;
        border-color: $color_scarlet;
      }

      .ant-checkbox-disabled .ant-checkbox-inner {
        background: $color_waterloo;
        border: 2px solid #848599 !important;
      }

      ::after {
        border-color: $color_white !important;
      }

      .ant-checkbox-inner {
        border: 2px solid #848599;

        &::after {
          left: 14.5%;
        }
      }

      .ant-checkbox-wrapper-disabled {
        span {
          color: $color_trout;
        }
      }
    }
  }
}

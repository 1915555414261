// size screen responsive
// Small devices (landscape phones, 576px and up)
// Extra large devices (large desktops, 1200px and up)
// Large devices (desktops, 992px and up)
// Medium devices (tablets, 768px and up)
$breakpoint_mobile_max: 575px;
$breakpoint_tablet_max: 767px;
$breakpoint_desktop_max: 991px;
$breakpoint_large_desktop_max: 1199px;
$breakpoint_ultra_desktop_max: 1600px;

//color
$color_white: #fff;
$color_black: #000;
$color_gray: #e6e8ec;
$color_trout: #4f5566;
$color_trout_0_3: #4f55664d;
$color_corn: #eda82d;
$color_scarlet: #ef7224;
$color_cinder: #141320;
$color_mercury: #e2e2e2;
$color_laurel: #159800;
$color_lariel_0_3: #1598004d;
$color_radical: #ff224a;
$color_radical_0_3: #ff224a4d;
$color_silver: #adadad;
$color_silver_0_3: #6969694d;
$color_waterloo: #848599;
$color_french_rose: #ef466f;
$color_big_stone: #172849;
$color_titan_white: #e7e9ff;
$color_mine: #2d2d2d;
$color_nft_name: rgba(23, 40, 73, 0.7);
$color_raven: #777e90;
$color_tundora: #4a4a4a;
$color_oasis: #feead0;
$color_wild_sand: #f6f6f6;
$color_dove_gray: #727171;
$color_charade_0_5: #23262f80;
$color_amber: linear-gradient(180deg, #ff9900 0%, #ffa800 54.69%, #ffc301 100%);

// font
$blinker: 'Blinker';

@font-face {
  font-family: $blinker;
  src: url('../resources/fonts/Blinker-Regular.ttf');
}

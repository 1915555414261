.app-header {
  background-color: $color_white;
  border-bottom: 1px solid $color_gray;
  padding: 0 32px;
  display: flex;
  justify-content: flex-end;

  .dropdown {
    &__text {
      margin-right: 12px;
      margin-left: 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $color_black;
    }
  }
}

.header-overlay {
  &__detail {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;

    .icon {
      height: 18px;
      width: 18px;
      margin-right: 6px;
      display: flex;
      justify-content: center;
    }
  }

  .overlay-menu {
    border-right: none;
    border-top: 1px solid $color_gray;

    &__item {
      padding-left: 6px;
      width: 133px;
    }
  }
}

html,
body {
  font-size: 14px;
  font-family: $blinker, 'Poppins', sans-serif;
  @include scrollbars();

  #root {
    height: 100%;

    .App {
      height: 100%;
    }
  }
}

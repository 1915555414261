.form-item {
  height: 100%;

  &__label {
    letter-spacing: 0.015em;
    padding-bottom: 8px;
    padding-top: 20px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 21px;
    color: $color_cinder;
    font-weight: 600;
  }

  &__description {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 4px;
    color: $color_trout;
  }

  .ant-input {
    height: 40px;
    width: 100%;
    border-radius: 5px;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: $color_waterloo;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.015em;
    }
  }

  .ant-input-textarea-show-count {
    &::after {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.015em;
      color: $color_waterloo;
    }
  }

  textarea {
    height: 148px !important;
    @include scrollbars();
  }

  .ant-select {
    width: 100%;
    height: 40px;
    border-radius: 5px;

    .ant-select-selector {
      @include scrollbars();
      overflow: auto;
      border-radius: 5px;
      height: 100%;
      align-items: center;

      &:hover,
      &:focus {
        box-shadow: none;
      }

      input {
        margin: 0;
        padding: 0;
      }
    }

    .ant-select-selection-item {
      height: 100%;
      align-items: center;
      @include textEllipsis(1);
      display: inline-flex;
    }

    .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
      color: $color_waterloo;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.015em;
    }
  }

  .ant-select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-input-search {
    .ant-input-group {
      .ant-input-group-addon {
        button {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}

.ant-switch-checked {
  opacity: 1;
}

.error-text {
  color: $color_french_rose;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}
